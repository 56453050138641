.custom-icon {
  position: relative;
}
.custom-icon .box {
  position: absolute;
  top: 5px;
  /* right: 0; */
  left: 5px;
  white-space: nowrap;
  background-color: white;
  padding: 0 7px;
  border-radius: 10px;
  /* margin: auto; */
}
